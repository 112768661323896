<template>
    <div class="row bg-white">
        <div class="col-md-12">
            <h3 class="mt-4">Quản lý BOT</h3>
        </div>
        <div class="content col-12 row">
            <div v-for="(item, index) in data" :key="index" class="col-md-4 p-0 rounded bg-white m-xl-2 m-lg-1">
                <div class="border-violet" style="height: 250px; position: relative; ">
                    <div class="p-4 bg-violet text-white rounded">{{item.bot ? item.bot.name : ''}}</div>
                    <div class="p-4" style="padding-bottom: 50px;">
                        {{item.bot ? item.bot.description : ''}}
                    </div>
                    <div class="d-flex justify-content-center" style="position: absolute; bottom: 10px; left: 0; width: 100%;">
                        <el-button  class="bg-danger text-white" v-if="item.bot_user && item.bot_user.status == 1" @click="actionBot(item.id, false, item)">
                            Ngắt kết nối
                            <i class="el-icon-right"></i>
                        </el-button>
                        <el-button class="bg-success text-white" v-else @click="actionBot(item.id, true, item)">
                            Kết nối ngay
                            <i class="el-icon-right"></i>
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_BOT_USER, ACTION_BOT_USER} from "@/core/services/store/telegram/telegram.module";
import Swal from 'sweetalert2'

export default {
    name: 'ManagementBotsIndex',
    components: {

    },
    data() {
        return {
            data: {}
        }
    },
    mounted() {
        this.getData();
    },
    methods: {

        getData() {
            this.$store.dispatch(GET_BOT_USER, {
            }).then((res) => {
                this.data = res.data;
                console.log(this.data);
            }).catch((response) => {
            });
        },
        actionBot(id, action, item)
        {
            if (action == true) {
                window.open( `${item.bot.user_name}?start=${this.currentUser.id}`);
                return;
            }

            Swal.fire({
                    title: `Bạn có chắc chắn muốn ngừng nhận thông báo từ BOT ${item.bot.name} ?`,
                    text: "",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Đồng ý',
                    showLoaderOnConfirm: true,
                    cancelButtonText: 'Hủy',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.actionBotUser(action == false ? item.bot_user.id : '', item.bot);
                    }
                });
        },

        actionBotUser(id, bot)
        {
            this.$store.dispatch(ACTION_BOT_USER, {
                id: id,
                token: bot.api_key
            }).then((res) => {
                this.$notify({
                    title: 'Ngừng đăng ký bot thành công!',
                    message: 'Thành công',
                    type: 'success'
                });
                this.getData();
            }).catch((response) => {
            });
        }


    },
    computed: {
        ...mapGetters(['currentUser'])
    }
}
</script>
<style>
.bg-violet {
    background-color: #472F92;
}

ul {
    list-style-type: none;
}

.text-violet {
    color: #8320e5;
}
th {
    padding: 10px;
}

.report-cell {
    padding: 8px;
    border: 1px solid #ddd;
    word-break: break-word;
    vertical-align: top;
}

.report-cell ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.report-cell ul li {
    margin-bottom: 4px;
}

.bold {
    font-weight: bold;
}


/* Điều chỉnh độ rộng của các cột */
.report-cell:nth-child(1) {
    width: 20%; /* Độ rộng của cột 1 */
}

.report-cell:nth-child(2) {
    width: 25%; /* Độ rộng của cột 2 */

}

.report-cell:nth-child(3) {
    width: 10%; /* Độ rộng của cột 3 */
}

.report-cell:nth-child(4) {
    width: 15%; /* Độ rộng của cột 4 */

}

.report-cell:nth-child(5) {
    width: 30%; /* Độ rộng của cột 5 */
}

.pointer {
    cursor: pointer;
}

.pheight-0 {
    padding-top: 20%;
}

.pheight-1 {
    padding-top: 5%;
}

.pheight-2 {
    padding-top: 3%;
}

.pheight-3 {
    padding-top: 4%;
}
th {
    padding: 10px;
}
.col-md-4 {
    max-width: 31% !important;
}
.border-violet {
    border: 1px solid #69339b;
    border-radius: 5px;
}
</style>